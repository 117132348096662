<template>
  <div>
    <Upload v-if="apiSrc" id="iviewUp" :show-upload-list="false" :on-progress="handleProgressQuill" :on-success="handleSuccessQuill" :on-error="handleErrorQuill" :format="['xlsx', 'xls']" :headers="header" :multiple="multiple" :name="name" :max-size="20480" :action="url" :on-format-error="onFormatError">
      {{ label }}
    </Upload>
    <Spin fix v-show="loading">
      <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
      <!-- <div>上传中...</div> -->
    </Spin>
    <!-- 错误数据弹窗 -->
    <Modal v-model="errorInfo" label-position="left" width="600" :footer-hide="true" :mask-closable="false" :closable="false" class-name="vertical-center-modal">
      <div class="topModal clearfix">
        <!-- <img src="@/assets/images/tipsPostImg.png" class="tipsImg fl" /> -->
        <p class="ptitle">{{message}}!</p>
        <div class="flDiv fl">
          <p v-for="item in errorData">{{item}}</p>
        </div>
      </div>
      <div class="foot">
        <span class="pageBtn finger btnCancle" @click="errorInfo = false">取消</span>
        <span class="pageBtn finger marginLeft20 btnSure" @click="errorInfo = false">确定</span>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'formUpload',
  props: {
    label: String, // 按钮文字
    apiSrc: String, // 上传接口
    name: String, // 上传的文件字段名
    multiple: Boolean || false,
  },
  data() {
    return {
      loading: false,
      url: 'example',
      header: '',
      errorInfo: false,
      message: '',
      errorData: []
    }
  },
  created() {
    this.url = this.apiSrc
    this.header = {
      Authorization: 'Bearer ' + localStorage.getItem('adminohoqicbi=='),
    }
  },
  methods: {
    // 文件类型校验
    onFormatError() {
      this.$Message.error('只允许上传xlsx,xls格式的文件')
    },
    // 上传失败
    handleErrorQuill() {
      this.loading = false
      this.$Message.error('文件上传失败')
    },
    // 上传成功
    handleSuccessQuill(res) {
      if (!res.status) {
        if(res.data) {
          this.errorData = res.data
          this.errorInfo = true
          this.message = res.message
        } else {
          this.$Message.warning({
            content: res.message,
            duration: 2
          })
        }
        
      } else {
        this.$emit('get-data', res.data)
      }
      this.loading = false
    },
    // 上传中
    handleProgressQuill() {
      this.loading = true
    },
  },
}
</script>

<style scoped lang="less">
#iviewUp {
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  border: none;
  text-align: center;
  box-sizing: border-box;
}
/deep/ .ivu-upload-select {
  width: 100% !important;
}
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.demo-spin-col {
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}
.topModal {
  padding-bottom: 20px;
  margin-top: 14px;
  .tipsImg {
    width: 36px;
    height: 36px;
    margin: 0 13px 0 9px;
  }
  .ptitle{
      font-size: 18px;
      color: #0e0d0d;
      font-weight: bold;
      margin: 15px 0px;
    }
  .flDiv {
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    p{
      // height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
  }
}
</style>
